<template>
  <div v-if="$route.path !== '/login'">
    <b-navbar
      toggleable="lg"
      class="align-items-lg-center container-p-x"
      style="background: rgba(153, 0, 0)"
    >
      <!-- Brand -->
      <b-navbar-brand>
        <img
          src="/images/para_logos/SVG/neumaticosParaImagotipoLinea.svg"
          height="40"
      /></b-navbar-brand>

      <!-- Sidenav toggle -->
      <b-navbar-nav
        class="align-items-lg-center mr-auto mr-lg-4"
        v-if="sidenavToggle"
      >
        <a
          class="nav-item nav-link px-0 ml-2 ml-lg-0"
          href="javascript:void(0)"
          @click="toggleSidenav"
        >
          <i class="ion ion-md-menu text-large align-middle" />
        </a>
      </b-navbar-nav>

      <!-- Navbar toggle -->
      <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

      <b-collapse is-nav id="app-layout-navbar">
        <b-navbar-nav class="sidenav-link align-items-lg-center">
          <b-nav-item
            >{{ language.ParaAfterLoginMenu.UsuarioConectado }}:
            <strong>{{ nombre }}</strong></b-nav-item
          >
          <b-nav-item @click="cerrarSesion">{{
            language.ParaAfterLoginMenu.CerrarSesion
          }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="align-items-lg-center ml-auto">
          <b-nav-item v-if="language.Idioma == 'Español'">{{
            date
          }}</b-nav-item>
          <b-nav-item v-if="language.Idioma == 'Inglés'">{{
            dateEng
          }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <!-- Idiomas -->
      <div class="d-flex" style="cursor: pointer; float: right;">
        <span @click="spanish"
          ><img
            src="/images/flags/espana.png"
            weight="20"
            height="20"
            class="mr-1"/></span
        ><span @click="english"
          ><img
            src="/images/flags/reino-unido.png"
            weight="20"
            height="20"
            class="mr-1"
        /></span>
      </div>
    </b-navbar>
  </div>
</template>
<style>
.nav-link {
  color: white !important;
}
.nav-link:hover {
  color: red !important;
}
</style>
<script>
const es = require("../../public/json/idiomas/es.json");
const en = require("../../public/json/idiomas/en.json");

export default {
  name: "app-layout-navbar",
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    },
    date: {
      type: String
    },
    dateEng: {
      type: String
    },
    idioma: {
      type: String
    }
  },
  data() {
    return {
      language: es,
      // array de objetos que recibimos de la bbdd con los datos que queremos mostrar
      nombre: "",
      usuario: "",
      rol: ""
    };
  },
  watch: {
    idioma: function(newValue) {
      if (newValue === "Español") {
        this.language = es;
      } else if (newValue === "Inglés") {
        this.language = en;
      }
    }
  },
  methods: {
    english() {
      this.language = en;
      localStorage.setItem('language', 'en');
      this.$emit("language", this.language.Idioma);
    },
    spanish() {
      localStorage.setItem('language', 'es');
      this.language = es;
      this.$emit("language", this.language.Idioma);
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    cerrarSesion() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      localStorage.removeItem("language");
      this.$router.push({
        path: "/login"
      });
    }
  },
  created() {
    const usuario = localStorage.getItem("user");
    const rol = localStorage.getItem("role");
    const nombre = localStorage.getItem("name");
    this.nombre = nombre;
    this.rol = rol;
    this.usuario = usuario;
    let lang = localStorage.getItem('language');
    switch (lang) {
      case 'es':
        this.language = es;
        break;
      case 'en':
        this.language = en;
        break;
      default:
         this.language = es;
    }
  }
};
</script>
