<template>
  <div v-if="$route.path !== '/login'">
    <sidenav
      :orientation="orientation"
      :class="curClasses"
      style="background: #D90009 !important"
    >
      <!-- Inner -->
      <div
        class="sidenav-inner"
        :class="{ 'py-1': orientation !== 'horizontal' }"
      >
        <sidenav-menu
          icon="fa-solid fa-users"
          :active="isMenuActive('/lista-clientes') || isMenuActive('/informe-clientes')"
          :open="isMenuOpen('/lista-clientes') || isMenuOpen('/informe-clientes')"
        >
          <template slot="link-text">{{ language.ParaAfterLoginMenu.Clientes }}</template>
          <sidenav-router-link
            icon="fa-solid fa-table-list"
            to="/lista-clientes"
            :exact="true"
            >{{ language.ParaAfterLoginMenu.ListaClientes }}</sidenav-router-link
          >
          <sidenav-router-link
            icon="fa-solid fa-chart-line-up"
            to="/informe-clientes"
            :exact="true"
            >{{ language.ParaAfterLoginMenu.InformeClientes }}</sidenav-router-link
          >
        </sidenav-menu>
        <sidenav-router-link
          icon="fa-solid fa-tire"
          to="/control-pedidos"
          :exact="true"
          >{{ language.ParaAfterLoginMenu.ControlPedidos }}</sidenav-router-link
        >
        <sidenav-router-link
          icon="fa-solid fa-chart-column"
          to="/informe-productos"
          :exact="true"
          >{{ language.ParaAfterLoginMenu.InformeProductos }}</sidenav-router-link
        >
        <sidenav-router-link
          icon="fa-solid fa-table-list"
          to="/informe-tarifas"
          :exact="true"
          >{{ language.ParaAfterLoginMenu.InformeTarifas }}</sidenav-router-link
        >
      </div>
    </sidenav>
  </div>
</template>
<style>
.sidenav-link {
  color: white !important;
}
.sidenav-item:hover {
  background-color: rgba(153, 0, 51) !important;
}
</style>

<script>
const es = require("../../public/json/idiomas/es.json");
const en = require("../../public/json/idiomas/en.json");
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    orientation: {
      type: String,
      default: "vertical"
    },
    idioma: {
      type: String
    }
  },
  data() {
    return {
      language: es,
    };
  },
watch: {
    idioma: function(newValue) {
      if (newValue === "Español") {
        this.language = es;
      } else if (newValue === "Inglés") {
        this.language = en;
      }
    }
  },
  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    }
  },
  created() {
    const usuario = localStorage.getItem("user");
    const rol = localStorage.getItem("role");
    const nombre = localStorage.getItem("name");
    this.nombre = nombre;
    this.rol = rol;
    this.usuario = usuario;
     let lang = localStorage.getItem('language');
    switch (lang) {
      case 'es':
        this.language = es;
        break;
      case 'en':
        this.language = en;
        break;
      default:
         this.language = es;
    }
  }
};
</script>
